import Logo from '../assets/Images/logo.png';


const Header = () => {
    return <div className="min-h-full">
                <nav className='bg-white-50'>
                <div class="mx-auto max-w-20xl px-4 sm:px-6 lg:px-8">
                    <div class="flex h-24  justify-start">
                        <div class="flex items-center">
                            <div >
                                <img class="h-100 w-100 mt-5" src={Logo} alt="EGZAKTA"/>                    
                            </div>
                        </div>
                    </div>
                </div>
                </nav>
                
    </div>
}

export default Header;