import { useEffect, useState } from "react";

const SystemPrompt = () => {

    const [prompt, setPrompt] = useState('');

    useEffect(()=> {
        console.log(prompt);

        // const p = prompt.replace()
        localStorage.setItem("system_prompt", prompt);
    }, [prompt])



    return <div className="container w-4/12" >
         <div className="flex-grow ml-4 mr-4">
            <div className="w-full">
            <label className="text-lg font-semibold">System prompt</label>
            <textarea onBlur={(e) => setPrompt(e.target.value)} id="message" rows="20" class="block p-2.5 w-full text-sm text-gray-900 bg-gray rounded-lg border border-sky-900 focus:ring-sky-900 focus:border-sky-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="System prompt"></textarea>
            </div>

            <div className="flex-grow ml-4 mr-4">
                <pre className="preClass"> {
`
IMPORTANT:Primarily use data from retrieved text: {{contexts}}.
chat history:
{% for item in chat_history %}
user:
{{ item.inputs.chat_input }}
assistant:
{{ item.outputs.chat_output }}
 
{% endfor %}
 
user question:
{{ chat_input }}`
                }
                </pre>
            </div>
          </div>


         </div>
    
}


export default SystemPrompt;