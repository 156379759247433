import { useEffect, useState } from 'react';
import micOff from '../assets/Images/MicOff.png';
import micOn from '../assets/Images/MicOn.png';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const ChatInput = ({ input, setInput, handleMessageSubmit, isSending }) => {
  console.log(isSending)
  // Hook to get transcript (the speech-to-text)
  const { transcript } = useSpeechRecognition();
  const [lastTranscript, setLastTranscript] = useState(transcript);
  const [isListening, setIsListening] = useState(false);

  // Turns microphone on and off
  function handleToggleListening() {
    if (isListening) {
      SpeechRecognition.stopListening();
      setIsListening(false);
    } else {
      SpeechRecognition.startListening({ continuous: true });
      setIsListening(true);
    }
  }

  const handleEnter = (event) => {
    if (event.key === 'Enter') handleMessageSubmit();
  };

  // Add transcript to input field when transcript changes
  useEffect(() => {
    setTimeout(() => {
      const cleanedTranscript = transcript.trim().replace(/\s+/g, ' ');
      if (cleanedTranscript !== lastTranscript) {
        setInput(input + cleanedTranscript.slice(lastTranscript.length));
        setLastTranscript(cleanedTranscript);
      }
    }, 800);
  }, [transcript, input, setInput, lastTranscript]);

  return (
    <>
      <div className="m-8">
        <div className="relative bottom-0 left-0 flex flex-row items-center h-18 bg-white w-full px-4 p-6">
          <div className="flex-grow ml-4 mr-4">
            <div className=" w-full" title="Type a prompt">
              <input
                type="text"
                maxLength={500}
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={handleEnter}
                placeholder="Send a message"
                className="flex w-full text-sm rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
              />
            </div>
          </div>
          <button
            className={
              isListening
                ? 'flex items-center justify-center text-green-100 ml-4'
                : 'flex items-center justify-center text-black ml-4'
            }
            onClick={handleToggleListening}
            title={isListening ? 'Turn Off Mic' : 'Turn On Mic'}>
            {!isListening ? <img src={micOn} alt="micOn" /> : <img src={micOff} alt="micOff" />}
          </button>
          <div className="ml-4">
            <button
              className="flex items-center justify-center bg-sky-700 hover:bg-sky-50 rounded-xl text-white px-2 py-1 flex-shrink-0"
              title={isSending ? 'Sending...' : 'Send Message '}
              onClick={handleMessageSubmit}
              disabled={isSending}>
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                </svg>

              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatInput;