import './App.css';
import Chatbot from './pages/Chatbot';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <>
      <Chatbot/>
      <Toaster containerClassName='react-toast' position='top-center' reverseOrder={false}/>
    </>
  );
}

export default App;
