
import Header from "../components/Header";
import Main from "../components/Main";
import SystemPrompt from "../components/SystemPrompt"

const Chatbot = () => {
    return (
        <div className="h-full"> 
                <Header/>
                <div className="flex flex-row w-full   overflow-x-hidden mt-4 ml-2 mr-4">
                    <SystemPrompt/>
                    <Main/>
                </div>
                    
        </div>
    )
}


export default Chatbot;
