import axios from 'axios';
const VITE_REACT_APP_API_URL='https://egzakta-braintrain-pf.azurewebsites.net/score';

export async function sendMessageToServer(message) {
    const messages = localStorage.getItem('messages')
    ? JSON.parse(localStorage.getItem('messages'))
        .filter((message) => message.isUser)
    : [];

  const ai_messages = localStorage.getItem('messages')
    ? JSON.parse(localStorage.getItem('messages'))
        .filter((message) => message.isAnswer)
    : [];

  let allQuestions = messages.map((message) => message.text); //user messages
  // lastThreeMessages.reverse().unshift(message);

  let allAnswers = ai_messages.map((message) => message.text); //ai messages
  // lastTwoAiMessages.reverse().unshift(message);

  // payload treba da bude drugaciji, saljemo celu istoriju
  // chat_input: question, chat_history:[{inputs: {chat_input: "question"}, outputs: {answer: "answer"}}]

  let chat_history = [];
  for (let i = 0; i < allQuestions.length; i++){
    chat_history.push({
      inputs: {
        chat_input: allQuestions[i]
      },
      outputs: {
        answer: allAnswers[i]
      }
    })
  }

  const system_prompt = localStorage.getItem('system_prompt') ? localStorage.getItem('system_prompt') : '';

  const payload = {
    chat_input: message,
    chat_history: chat_history,
    chat_prompt: system_prompt,
  };

  if (message.length > 500) {
    throw new Error('Limit is 500 characters per prompt');
  } else {
    const data = await axios.post(VITE_REACT_APP_API_URL, payload);
    const answer = data.status === 200 ? data.data.answer :'';

    // console.log(JSON.stringify(data));

    return answer;
  }
}