import { useEffect, useRef, useState } from "react";
import Chatmessage from "./Chatmessage";
import ChatInput from "./ChatInput";
import Info from "./Info";
import toast from "react-hot-toast";
import { sendMessageToServer } from "../services/chat.service";

const initialMessage = [
  {
    text: "Welcome! I'm your virtual assistant.",
    user: false, 
    isAnswer: false
  }
];

const Main = () => {
    const [messages, setMessages] = useState(() => {
      const savedMessages = localStorage.getItem('messages');

      if(!savedMessages){
        localStorage.setItem('messages', JSON.stringify(initialMessage));
        return initialMessage;
      }
      return JSON.parse(savedMessages);
    })
    const [input, setInput] = useState('');
    const [clear, setClear] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const scroll = useRef();


    useEffect(() => {
      localStorage.setItem('messages', JSON.stringify(initialMessage));
      setMessages(JSON.parse(localStorage.getItem('messages')))
      setClear(false)
    }, [clear])

    const handleCopyToClipboard = (text) => {
      navigator.clipboard.writeText(text).then(() => {
        toast.success('Copied to clipboard.');
      }).catch((error) => {
        toast.error(`Failed to copy: ${error.message}`);
      })
    } 

    const handleClearLocalStorage = () => {
      localStorage.removeItem('messages');

      setMessages([]);
      setClear(true);
      toast.success('Cleared All Messages');
    }
    const handleMessageSubmit = async () => {
      if (input === ''){
        toast.error('Input is empty. Please enter a question or instructions');
        navigator.vibrate(700);
      }else 
      {
        setIsSending(true);
      }

      const userMessage ={text: input, user: true};
      toast.promise(sendMessageToServer(input), {
        loading: 'Sending message...',
        success: (response) => {
          const aiMessage = {text: response, user: false, isAnswer: true};

          setMessages((prevMessages) => [...prevMessages, userMessage, aiMessage]);
          setInput('');

          //add ai message to local storage
          localStorage.setItem('messages', JSON.stringify([...messages, userMessage, aiMessage]))
          setIsSending(false);


          //scroll into view
          if(scroll.current){
            setTimeout(
              () => scroll.current.scrollTo({
                top: scroll.current.scrollHeight,
                behaviour: 'smooth',
              }), 100
            )
          }
        },
        error: (error) => {
          setIsSending(false);
          toast.error(`Error: ${error.message}`);
        }
      })
    }
    return <>
    <div className="flex flex-col flex-auto">
        {/* <div className="flex flex-col flex-auto flex-shrink-0 bg-grey h-full"> */}
        <label className="text-lg font-semibold">Chatbot</label>
        <div className="flex flex-col flex-auto flex-shrink-0 bg-gray h-full">
        {messages.length > 0 ? (
            <div ref={scroll} className="flex flex-col h-full overflow-x-auto mb-4">
              <div className="flex flex-col h-full">
                <Chatmessage messages={messages} handleCopyToClipboard={handleCopyToClipboard} />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          ) : (
            <Info></Info>
          )}

            {messages.length > 0 && (
            <center>
              <button onClick={handleClearLocalStorage}
                className="flex items-center justify-center text-sm text-gray-50/70 border-solid border-[1px] border-gray-50/70 rounded-[0.5rem]  px-0 py-1 mb-3 p-20 flex-shrink-0 w-44">
                Clear Conversation
              </button>
            </center>
          )}

            <ChatInput input={input} setInput={setInput} handleMessageSubmit={handleMessageSubmit} handleClearLocalStorage={handleClearLocalStorage} messages={messages} isSending={isSending} />
        </div>
    </div>
</>;
}
      

export default Main;